import * as React from 'react';
import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import AnimatedPage from "../components/AnimatedPage";


gsap.registerPlugin(ScrollTrigger);

const About = (props) => {

  let line1 = useRef(null);
  let line2 = useRef(null);

  useEffect(() => {

    gsap.set(".par", {y: 200, opacity: 0});

    gsap.to(".par", {
      ease: "sine",
      y: 0,
      opacity: 1
    });
    gsap.to(".headcirc", {
      ease: "power3.out",
      rotation: 200,
      scrollTrigger: {
        trigger: ".headcirc",
        start: "center 10%",
        end: "500% 10%",
        toggleActions: "play none none reverse",
        markers: false
      }
    });

    setTimeout(() => {
      ScrollTrigger.refresh();
      console.log("scrolltrigger refresh!");
    }, 1000);

    }, [line1, line2]);

    return (
      <AnimatedPage>

        <div className="sub-cont">

        <div className="serv-blue">

        <div className="sectionhead">
          <img className="headcirc" src="../../images/combocircl.svg" alt="Combo Circle" />
          <h2 className="ourservices">About Elixr</h2>
        </div>

          <div className="par ab">
            <p>
              Elixr is built upon 30 years of experience working with people just like you: industry leaders who influence and motivate their target audience with messaging that inspires&nbsp;action.
              <br /><br />
              We approach every project with thoughtfulness, structure and an expertise in visually transforming complex content into audience-friendly messaging. We’re invested in your message and goals, and our proven record of integrity shows that we’re equally committed to confidentiality, transparent pricing and realistic&nbsp;timelines.
              <br /><br />
              Through brainstorming, planning and creative collaboration with your team, we’re dedicated to creating proposals, presentations and materials that help you win more&nbsp;business.
            </p>
          </div>

          </div>


        </div>
        </AnimatedPage>

    );
};

export default About;
