import React from 'react';
import { Route, Routes, useLocation } from "react-router-dom";
import './App.scss';

import { AnimatePresence } from "framer-motion";

import Nav from './components/Nav';
import Footer from './components/Footer';

import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';

import Section from "./pages/Section";

import ScrollToTop from './components/ScrollToTop';

function App() {
  const location = useLocation();
  return (
    <div className="container" >
    <AnimatePresence exitBeforeEnter>
      <Nav />
      <main>
      <ScrollToTop />

        <div className="wrapper">
         <Routes key={location.pathname} location={location}>
            <Route element={<Section />}>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
           </Route>
         </Routes>
       </div>

      </main>
      <Footer />
    </AnimatePresence>
    </div>

  );
}

export default App
