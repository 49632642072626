import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { NavLink } from "react-router-dom";

const animation = gsap.timeline({ paused: true, reversed: true })

const Nav = () => {

  const navRef = useRef();
  const menuRef = useRef();


  useEffect(() => {

      const menuLine1 = menuRef.current.querySelector(".menu-line-1")
      const menuLine2 = menuRef.current.querySelector(".menu-line-2")
      const menuLine3 = menuRef.current.querySelector(".menu-line-3")

      gsap.set(navRef.current, {left: "-100vw"})

      animation
        .to(navRef.current, {ease: "power3.inOut", left: "0px", duration: .25})
        .to(menuLine1, { rotate: "45deg", y: "15px", duration: .25 }, "<")
        .to(menuLine2, { x: "-100%", duration: .25 }, "<")
        .to(menuLine3, { rotate: "-45deg", y: "-15px", duration: .25 }, "<")

          const showAnim = gsap.from('.naving', {
            yPercent: -100,
            paused: true,
            duration: 0.05
          }).progress(1);

          ScrollTrigger.create({
            start: "top top",
            end: 99999,
            onUpdate: (self) => {
              animation.reversed() ? (
                self.direction === -1 ? showAnim.play() : showAnim.reverse()
              ) : (
                console.log("open")
              )
              }
          })

          setTimeout(() => {
            ScrollTrigger.refresh();
            console.log("scrolltrigger refresh!");
          }, 1000);

  })

  const handleMenuClick = () => {
    animation.reversed() ? animation.play() : animation.reverse();
  }

    return (
      <>
      <nav className="naving">
        <NavLink className="home-logo" to="/"><img src="/images/elixrlogo_white.svg" alt="Elixr Logo" /></NavLink>

        <div className="mob-nav" ref={navRef}>
          <ul className="mobile-links">
            <NavLink className="b-link" activeClassName="active" to="/about" onClick={handleMenuClick}><li>About</li></NavLink>
            <NavLink className="b-link" activeClassName="active" to="/services" onClick={handleMenuClick}><li>Services</li></NavLink>
            <a className="b-link"  onClick={handleMenuClick} href="mailto:info@elixr.biz?subject=I%20want%20the%20right%20mix%20of%20commentary%20and%20creativity">
              <li>Contact</li>
            </a>
          </ul>
        </div>

        <div className="menu" ref={menuRef} onClick={handleMenuClick}>
          <div className="menu-line-wrapper">
            <div className="menu-line menu-line-1"></div>
            <div className="menu-line menu-line-2"></div>
            <div className="menu-line menu-line-3"></div>
          </div>
        </div>

        <div className="links">
        <ul className="site-nav">
          <NavLink className="a-link" activeClassName="active" to="/about"><li>About</li></NavLink>
          <NavLink className="a-link" activeClassName="active" to="/services"><li>Services</li></NavLink>
          <a className="a-link" href="mailto:info@elixr.biz?subject=I%20want%20the%20right%20mix%20of%20commentary%20and%20creativity">
            <li>Contact</li>
          </a>
        </ul>
        </div>
      </nav>
      </>
    )
  }

export default Nav;
