import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import WavesLarge from "../components/WavesLarge";

import AnimatedPage from "../components/AnimatedPage";

gsap.registerPlugin(CSSRulePlugin);
gsap.registerPlugin(ScrollTrigger);


const Home = () => {
  let line1 = useRef(null);
  let line2 = useRef(null);
  let line3 = useRef(null);

  useEffect(() => {

    gsap.fromTo([line1, line2, line3], 1,
      {
        y: 170,
        opacity: 0
      },
      {
        delay: .5,
        ease: "power3.out",
        y: 0,
        opacity: 100,
        stagger: {
          amount: .3
        },
    });

    let words = gsap.utils.toArray(".line-wrap");

    words.forEach((word, i) => {
      gsap.to(word, {
        ease: "sine",
        scrollTrigger: {
          trigger: word,
          start: "top 10%",
          end: "bottom center",
          toggleActions: "play none none reverse",
          markers: false
        },
        x: -50,
      });
    });

    gsap.to(".par", {
      ease: "sine",
      scrollTrigger: {
        trigger: ".par",
        start: "top 30%",
        end: "bottom center",
        toggleActions: "play none none reverse",
        markers: false,
      },
      x: 50,
    });

    gsap.to(".headcirc", {
      ease: "power3.out",
      rotation: 150,
      scrollTrigger: {
        trigger: words,
        start: "center 10%",
        end: "500% 10%",
        toggleActions: "play none none reverse",
        markers: false
      }
    });

    gsap.set(".sectionhead", {y: 100, opacity: 0});

    ScrollTrigger.batch(".sectionhead", {
      start: "-500 center",
      end: "-100 center",
      markers: false,
      onEnter: batch => gsap.to(batch, {opacity: 1, y: 0, stagger: 0.15})
    });


    gsap.set(".ball", {opacity: 0});

    ScrollTrigger.batch(".ball", {
      start: "-200 center",
      end: "-100 center",
      onEnter: batch => gsap.to(batch, {opacity: 1, stagger: {each: 0.15}}),
      markers: false
    });

    gsap.set(".oneblock", {y: 100, opacity: 0});

    ScrollTrigger.batch(".oneblock", {
      start: "top bottom-=100px",
      onEnter: batch => gsap.to(batch, {opacity: 1, y: 0, backgroundSize:"100%", stagger: 0.15}),
      onLeaveBack:batch => gsap.to(batch, {opacity: 0, y: 100, backgroundSize:"0%", stagger: 0.1})
    });

    gsap.to(".sidecirc", {
      ease: "power3.out",
      rotation: -175,
      scrollTrigger: {
        trigger: ".sidecirc",
        start: "10% center",
        end: "bottom center",
        scrub: 3,
        markers: false,
        duration: 5
      }
    });

    gsap.set(".strategy", {y: 100});

    ScrollTrigger.batch(".strategy", {
      start: "top bottom-=100px",
      onEnter: batch => gsap.to(batch, {y: 0, backgroundSize:"100%", stagger: 0.15}),
      onLeaveBack:batch => gsap.to(batch, {y: 100, backgroundSize:"0%", stagger: 0.1})
    });

    gsap.set(".revblock", {y: 100, opacity: 0});

    ScrollTrigger.batch(".revblock", {
      start: "top bottom-=100px",
      onEnter: batch => gsap.to(batch, {opacity: 1, y: 0, backgroundSize:"100%", stagger: 0.15}),
      onLeaveBack:batch => gsap.to(batch, {opacity: 0, y: 100, backgroundSize:"0%", stagger: 0.1})
    });

    setTimeout(() => {
      ScrollTrigger.refresh();
      console.log("scrolltrigger refresh!");
    }, 1000);

  }, [line1, line2, line3]);



  return (

    <AnimatedPage>

    <div className="sub-cont">

        <div className="landing">
        <div className="land2">
          <img className="headcirc" src="../../images/combocircl.svg" alt="Combo Circle" />
          <h1 className="home-text">
            <div className="line-wrap">
              <span ref={el => (line1 = el)} className="word l1" data-speed=".2">The</span>
            </div>
            <div className="line-wrap">
              <span ref={el => (line2 = el)} className="word l2" data-speed=".24">Right&nbsp;Mix
              </span>
            </div>
          </h1>
          <div ref={el => (line3 = el)} className="par"><p>Providing visual communication strategy for engaging proposals, presentations and marketing materials, so you can win more bids and&nbsp;business.</p></div>
        </div>
        </div>

        <WavesLarge />

        <div className="needs">
          <div className="sectionhead">
            <img className="htwocirc" src="../../images/circle_green.svg" alt="Green Circle" />
            <h2 className="blue">Your project needs a
            <span>visual communication <span className="und">strategy</span>.</span>
            <span>Here’s why</span></h2>
          </div>
          <div className="blocks">
          <svg className="onearrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86.6 430.4">
            <g fill="#4e7e89">
            <circle className="ball" cx="43.3" cy="187.15" r="9.45"/>
            <circle className="ball" cx="43.3" cy="127.91" r="9.45"/>
            <circle className="ball" cx="43.3" cy="68.68" r="9.45"/>
            <circle className="ball" cx="43.3" cy="9.45" r="9.45"/>
            <circle className="ball" cx="43.3" cy="246.38" r="9.45"/>
            <circle className="ball" cx="43.3" cy="305.61" r="9.45"/>
            </g>
            <polygon className="ball" fill="#4e7e89" points="43.3 355.4 86.6 355.4 64.95 392.9 43.3 430.4 21.65 392.9 0 355.4 43.3 355.4"/>
          </svg>
          <svg className="onearrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86.6 430.4">
            <g fill="#4e7e89">
            <circle className="ball" cx="43.3" cy="187.15" r="9.45"/>
            <circle className="ball" cx="43.3" cy="127.91" r="9.45"/>
            <circle className="ball" cx="43.3" cy="68.68" r="9.45"/>
            <circle className="ball" cx="43.3" cy="9.45" r="9.45"/>
            <circle className="ball" cx="43.3" cy="246.38" r="9.45"/>
            <circle className="ball" cx="43.3" cy="305.61" r="9.45"/>
            </g>
            <polygon className="ball" fill="#4e7e89" points="43.3 355.4 86.6 355.4 64.95 392.9 43.3 430.4 21.65 392.9 0 355.4 43.3 355.4"/>
          </svg>
            <div className="oneblock"><p>90% of companies create proposals & presentations with imbalanced messaging</p></div>
            <div className="oneblock"><p>Companies lose 40% of their audience’s attention when there’s no visual&nbsp;strategy</p></div>
          </div>
        </div>

        <div className="why">
          <div className="sectionhead">
            <img className="htwocirc" src="../../images/circle_green.svg" alt="Green Circle" />
            <h2 className="white">Why
            <span><span className="und">partner</span> with Elixr</span></h2>
          </div>
          <div className="popwords">
            <img src="/images/quotes.svg" alt="Begin Quotes" />
            <div className="wordbox"><span>Highest Level of&nbsp;Creativty</span> <span>Visionary</span> <span>Industry Leading</span> <span>Lifesaver</span></div>
            <img src="/images/endquote.svg" alt="End Quotes" />
          </div>
          <h3>Our clients have described us in many ways, but above all, we’re known as a <span>trusted&nbsp;partner.</span></h3>
          <div className="strategy">
            <p>Through our visual strategy, we help you stand out from the competition. With the right mix of narrative and visuals, we elevate your proposals and presentations in a way that keeps decision-makers engaged and excited to work with&nbsp;you.</p>
          </div>
        </div>

        <div className="customers">
          <div className="sectionhead">
          <img className="htwocirc" src="../../images/circle_green.svg" alt="Green Circle" />
            <h2 className="white"><span>What our <span className="und">customers</span></span>
            <span className="right">are saying</span></h2>
          </div>
          <div className="reviews">
            <div className="revblock"><p>Professional with the highest level of creativity, professionalism, quality and attention to schedule&nbsp;deadlines<span>– Bill Trombley, Director of Marketing Skanska USA Civil&nbsp;West</span></p></div>
            <div className="revblock"><p>Industry leading... I would recommend Elixr to any team to help them&nbsp;succeed<span>– Jeff Turner, VP Vulcan Materials&nbsp;Co.</span></p></div>
            <div className="revblock"><p>Even better than your original&nbsp;vision<span>– Michelle Marston, Regional Pursuit Manager, WSP&nbsp;USA</span></p></div>
            <div className="revblock"><p>One of the best visionaries I've had the pleasure to work&nbsp;with<span>– Thomas Anderson, Creative Director, Baer Enterprises,&nbsp;Inc.</span></p></div>
            <div className="revblock"><p>An eye for sharp, content-driven presentation with a penchant for project management and the ability to elicit the best&nbsp;work<span>– Michael Hytha, Editor, Bloomberg&nbsp;News</span></p></div>
            <div className="revblock"><p>A lifesaver for me and the&nbsp;company<span>– Shawn Carter, Senior Product Designer,&nbsp;McAfee</span></p></div>
          </div>
        </div>
      </div>

      </AnimatedPage>
  );
};

export default Home;
