import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

class Footer extends Component {

  render() {

    return (
      <>
      <footer>
        <div className="f-one">
          <NavLink className="footer-logo" to="/"><img src="/images/elixrlogo_color.svg" alt="Elixr Logo" /></NavLink>
        </div>

        <div className="f-two">
          <h3 className="lets">Let's Connect</h3>
          <p>We’d love to learn more about your project&nbsp;needs.</p>
          <a className="con" href="mailto:info@elixr.biz?subject=I%20want%20the%20right%20mix%20of%20commentary%20and%20creativity">
          <div className="button">
            <h3>Contact Us</h3>
          </div>
          </a>
          <a className="ln" href="https://www.linkedin.com/company/elixr-viscom-inc/" target="_blank" rel="noreferrer">
            <img src="/images/linkedin.svg" alt="LinkedIn Logo" />
          </a>
        </div>

        <div className="f-three">
          <div className="copywrite">
            <span>
            ©2023 Elixr VisCom, Inc.
            </span>
            <span>
            All rights reserved
            </span>
          </div>
        </div>
      </footer>

      </>
    )
  }
}

export default Footer;
