import * as React from 'react';
import { useEffect } from 'react';
import { gsap } from 'gsap';
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import ScrollTrigger from 'gsap/ScrollTrigger';

import AnimatedPage from "../components/AnimatedPage";

gsap.registerPlugin(CSSRulePlugin);
gsap.registerPlugin(ScrollTrigger);


const Services = () => {

  useEffect(() => {

    gsap.to(".headcirc", {
      ease: "power3.out",
      rotation: 200,
      scrollTrigger: {
        trigger: ".headcirc",
        start: "center 10%",
        end: "500% 10%",
        toggleActions: "play none none reverse",
        markers: false,
      }
    });

    gsap.set([".graphic, .oprocess"], {opacity: 0});
    gsap.to([".graphic, .oprocess"], {
      ease: "power3.in",
      opacity: 1,
      duration: 1
    });

    gsap.set(".info", {y: 100, opacity: 0});

    ScrollTrigger.batch(".info", {
      start: "-500 center",
      end: "center top",
      markers: false,
      onEnter: batch => gsap.to(batch, {opacity: 1, y: 0, backgroundSize:"100%", stagger: 0.15}),
      onLeaveBack:batch => gsap.to(batch, {opacity: 0, y: 100, backgroundSize:"0%", stagger: 0.1}),
    });

    let wlink = document.querySelector(".wlink");

    let animation = gsap.to(".wlink", {
      paused: true,
      scale: 1.1,
    });

    wlink.addEventListener("mouseenter", () => animation.play());
    wlink.addEventListener("mouseleave", () => animation.reverse());


  }, []);

    return (
      <AnimatedPage>

      <div className="sub-cont">

          <div className="serv-light">
            <div className="sectionhead">
              <img className="headcirc" src="../../images/combocircl.svg" alt="Combo Circle" />
              <h2 className="ourservices">Our Services</h2>
            </div>

            <h3 className="oprocess">
              Elixr Process
            </h3>

            <img className="graphic" src="../../images/infographic2.svg" alt="Infographic" />

            <h3 className="info">Our clients appreciate our collaborative, reputable process for proposals, projects and presentations. Here’s how we deliver quality, award-winning&nbsp;results.</h3>
          </div>

          <div className="serv-dark">
            <div className="bullet info">
              <h2 className="white">
                <span className="und">Visual Strategy</span>
              </h2>
              <h3>Stand Out</h3>
              <p>
                We partner with you to determine your differentiators and what makes you stand out. By identifying whether your content should be communicated through narrative or graphics, we present your message in a visually engaging way. This keeps your audience invested and keeps your company at the top of their&nbsp;list.
              </p>
            </div>

            <div className="bullet info">
              <h2 className="white">
                <span className="und">Content Hierarchy</span>
              </h2>
              <h3>A Proactive Approach</h3>
              <p>
                Through teamwork and analysis, we help you identify the best way to bring your core differentiators to the forefront. We offer clarity to incredibly complex content and support proposal managers in making it resonate with your&nbsp;audience.
              </p>

              <h3 className="secondsub">Partnering to Serve Clients</h3>
              <p className="wordcoop">
                Since 2017, Elixr has partnered with Word Coop to serve clients with writing and editing needs. Word Coop works primarily with architects and engineers to craft convincing and compliant responses to requests for proposals (RFPs).
                <a className="wlink" href="https://www.wordcoop.com/" target="_blank" rel="noreferrer"><img className="wdlogo" src="../../images/wordcooplogo.svg" /></a>
              </p>
            </div>

            <div className="bullet info">
              <h2 className="white">
                <span className="und">Layout Design</span>
              </h2>
              <h3>Visual Advantage</h3>
              <p>
                From proposals and presentations to creative marketing materials, we design your project from start to finish. We work with your team to define the visual balance, message hierarchy and overall presentation to deliver&nbsp;success.
              </p>
            </div>

            <div className="bullet info">
              <h2 className="white">
                <span className="und">Planning & Project Management</span>
              </h2>
              <h3>Less Stress – More Control</h3>
              <p>
                Proposal managers rely heavily on our team, and our long-standing clients value our trusted process. In addition to providing clarity and visual strategy, our organized process helps relieve your team from the pressure to deliver in a refined, visually compelling way — all while ensuring you maintain control over your&nbsp;project.
              </p>
            </div>

            <div className="bullet info">
              <h2 className="white">
                <span className="und">Industry Expertise</span>
              </h2>
              <h3>Your Trusted Partner</h3>
              <p>
                When we partner with you, you benefit from our team’s decades of experience in producing winning proposals for industry&nbsp;leaders.
              </p>
            </div>
          </div>

        </div>

        </AnimatedPage>

    );
};

export default Services;
