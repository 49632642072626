import React from "react"

class WavesLarge extends React.Component {


render() {
    return (

        <div className="wavesbox">
          <svg class="largeWaves" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
              <defs>                  <path id="large-wave" d="M-200 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
              </defs>
              <g>
                  <use href="#large-wave" x="100" y="0" fill="rgba(221,227,219,0.4" />
                  <use href="#large-wave" x="100" y="2" fill="rgba(221,227,219,0.5)" />
                  <use href="#large-wave" x="100" y="3.5" fill="rgba(221,227,219,0.7)" />
                  <use href="#large-wave" x="100" y="5" fill="rgba(221,227,219,1)" />
              </g>
          </svg>
        </div>

    )
  };
};

export default WavesLarge;
